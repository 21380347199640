import './bootstrap';
import '../css/app.css';
import 'primevue/resources/themes/lara-light-green/theme.css'
import 'primeicons/primeicons.css'
import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
import { createInertiaApp } from '@inertiajs/vue3';
import PrimeVue from 'primevue/config';
import PanelMenu from 'primevue/panelmenu';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import MultiSelect from 'primevue/multiselect';
import RadioButton from 'primevue/radiobutton';
import Card from 'primevue/card';
import Chart from 'primevue/chart';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';   // optional
import Row from 'primevue/row'; 
import Paginator from 'primevue/paginator';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Fieldset from 'primevue/fieldset';
import Breadcrumb from 'primevue/breadcrumb';
import Dialog from 'primevue/dialog';
import SplitButton from 'primevue/splitbutton';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import DynamicDialog from 'primevue/dynamicdialog';
import PickList from 'primevue/picklist';
import Listbox from 'primevue/listbox';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import Editor from 'primevue/editor';
import Password from 'primevue/password';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Calendar from 'primevue/calendar';
import InputIcon  from 'primevue/inputicon'
import FileUpload from 'primevue/fileupload';
import ConfirmDialog from 'primevue/confirmdialog';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import 'flowbite';

const pinia = createPinia();
const appName = import.meta.env.VITE_APP_NAME || 'Discover Holidays Ltd. Tours & Travel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(pinia)
            .use(PrimeVue)
            .use(ToastService)
            .use(DialogService)
            .use(ConfirmationService)
            .component('PanelMenu', PanelMenu)
            .component('InputText',InputText)
            .component('Button', Button)
            .component('Toast', Toast)
            .component('Checkbox', Checkbox)
            .component('RadioButton', RadioButton)
            .component('Card', Card)
            .component('Chart', Chart)
            .component('Dropdown', Dropdown)
            .component('DataTable', DataTable)
            .component('Column', Column)
            .component('ColumnGroup', ColumnGroup)
            .component('Paginator', Paginator)
            .component('PickList', PickList)
            .component('Fieldset', Fieldset)
            .component('Listbox', Listbox)
            .component('MultiSelect', MultiSelect)
            .component('Row', Row)
            .component('SplitButton', SplitButton)
            .component('Breadcrumb', Breadcrumb)
            .component('TabView', TabView)
            .component('TabPanel ', TabPanel)
            .component('Tag ', Tag)
            .component('Dialog', Dialog)
            .component('DynamicDialog', DynamicDialog)
            .component('Textarea', Textarea)
            .component('Editor', Editor)
            .component('Password', Password)
            .component('Calendar', Calendar)
            .component('InputIcon', InputIcon)
            .component('FileUpload', FileUpload)
            .component('ConfirmDialog', ConfirmDialog)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
